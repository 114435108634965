<template>
  <div
    class="arcprogress"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="titlebox">
      <div class="title">{{ title }}</div>
      <div class="notice">
        <el-tooltip placement="bottom" effect="light">
          <div slot="content">
            <p v-if="jltip == 1">
              “新闻热度“模块显示了同一时间区间内的新闻源数量与上一时期区间相比的百分比差异。
            </p>
          </div>
          <el-button @click="toggleTips()"> ? </el-button>
        </el-tooltip>
      </div>
    </div>
    <div
      id="chart"
      :style="{ width: width + 'px', height: height + 'px' }"
    ></div>
    <div class="numbox">
      <p class="left">
        舆情量：<span class="num">{{ jldata.all_count }}条</span>
      </p>
      <p class="right">
        {{ comment }}
        <span
          class="percent"
          :class="{
            up: jldata.articleCountRatio > 0,
            blow: jldata.articleCountRatio < 0
          }"
          >{{ jldata.articleCountRatio }}%</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import tools from "@/utils/tools";
export default {
  name: "jlringprogress",
  data() {
    return {
      loading: true,
      percentage: 0,
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 }
      ],
      tips: "",
      comment: ""
    };
  },
  mixins: [tools],
  props: {
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 200
    },
    jldata: {
      type: Object,
      default: function(e) {
        return e;
      }
    },
    title: {
      type: String,
      default: "同期新闻热度"
    },
    jltip: {
      type: Number,
      defalut: 0
    }
  },
  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      // return parseInt(Math.random() * 1000000);
      return this.getID();
    }
  },
  watch: {
    jldata: function(newVal) {
      this.filterData(newVal);
    }
  },
  mounted() {
    let _this = this;
    this.loading = false;
    console.log("progress", this.jldata);
    this.filterData(_this.jldata);
  },
  methods: {
    filterData(e) {
      // console.log("值", Math.abs(e.articleCountRatio));
      // let params = Math.abs(e.articleCountRatio);
      let params = "";
      if (e.buzzLevel == "noNews") {
        params = 100;
        this.comment = "无新闻";
      } else if (e.buzzLevel == "belowAverage") {
        params = 75;
        this.comment = "低于均值";
      } else if (e.buzzLevel == "Average") {
        params = 50;
        this.comment = "平均值";
      } else if (e.buzzLevel == "aboveAverage") {
        params = 25;
        this.comment = "高于均值";
      } else if (e.buzzLevel == "buzzing") {
        params = 0;
        this.comment = "热度极高";
      } else {
        this.comment = "比例";
      }
      this.initChart(params);
    },

    formatprogress(percentage) {
      // return percentage === 100 ? "满" : `${percentage}%`;
      return `${percentage}`;
    },
    initChart(params) {
      d3.select(".shadow").remove();
      var percent = params;
      console.log(percent);
      d3.pie()
        .value(function(d) {
          return d;
        })
        .sort(null);

      var w = 300,
        h = 200;
      var outerRadius = 100;
      var innerRadius = 85;
      var color = ["#070707", "#E99863", "#888888"];
      // var colorOld = "#F00";
      // var colorNew = "#0F0";

      var arc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(0)
        .endAngle(Math.PI);
      // console.log(1);
      var arcLine = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);
      // console.log(6);
      var svg = d3
        .select("#chart")
        .append("svg")
        // .attr({
        //   width: w,
        //   height: h,
        //   class: "shadow"
        // })
        .style("width", w)
        .style("height", h)
        .attr("class", "shadow")
        .append("g")
        .attr("class", "svgg")
        .attr("transform", "translate(" + w / 2 + "," + w / 2 + ")")
        .on("click", function() {
          // console.log(2);
          pathForeground
            .transition()
            .duration(1000)
            // .ease("cubic")
            .call(arcTween, Math.random() * 100, 0);
        });
      // console.log(5);
      svg
        .append("path")
        .attr("d", arc)
        .attr("transform", "rotate(-90)")

        .style("fill", color[0]);
      // .attr({
      //   // 'stroke-width': "1",
      //   // stroke: "#666666"
      // });
      // console.log(2);
      var pathForeground = svg
        .append("path")
        .datum({
          endAngle: Math.PI
        })
        .attr("d", arcLine)
        .attr("transform", "rotate(0)")

        .style("fill", color[1]);

      // console.log(3);

      // var middleCount = svg
      //   .append("text")
      //   .datum(0)
      //   .text(function(d) {
      //     return d;
      //   })
      //   .attr("class", "middleText")
      //   .attr("dx", -40)
      //   .attr("dy", 0)
      //   .style("fill", "#ff7272")
      //   .style("font-size", "40px")
      //   .style("font-weigth", "bold");

      var oldValue = 0;
      var arcTween = function(transition, newValue, oldValue) {
        transition.attrTween("d", function(d) {
          arcLine.startAngle(-Math.PI / 2);
          var interpolate = d3.interpolate(
            Math.PI / 2,
            Math.PI / 2 - Math.PI * (newValue / 100)
          );

          var interpolateCount = d3.interpolate(oldValue, newValue);
          return function(t) {
            d.endAngle = interpolate(t);

            // middleCount.text(Math.floor(interpolateCount(t)) + "%");

            return arcLine(d);
          };
        });
      };
      console.log(4);

      pathForeground
        .transition()
        .duration(1000)
        // .ease("cubic")
        .call(arcTween, percent, oldValue);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.arcprogress {
  position: relative;
  // background: black;
  background: #191919;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .titlebox {
    position: absolute;

    left: 10px;
    top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      color: #b4b4b4;
      font-weight: bold;
    }
    .notice {
      font-size: 12px;
      color: #b4b4b4;
      margin-left: 20px;
      cursor: pointer;
      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        background: #3a3a3a;
      }
    }
  }
  .numbox {
    position: absolute;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 30px;
    bottom: 10px;
    color: #b4b4b4;
    font-size: 12px;
    text-align: left;
    // margin-left: 20px;
    .left {
      .num {
        color: #33a8ee;
      }
    }
    .right {
      // margin-left: 40px;
      .percent {
        // font-size: 24px;
      }
      .up {
        color: #ff7373;
      }
      .blow {
        color: #40c5fd;
      }
    }
  }
  .shadow {
    text-align: center;
    .svgg {
      margin: 0 auto;
    }
  }
}
</style>
