<template>
  <div
    class="detailnews"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <ul class="newsbox" v-if="dynamicData.length > 0">
      <li v-for="item in dynamicData" :key="item" @click="openDrawer(item)">
        <div class="left">
          <div class="avr">
            <img :src="item.logoUrl" alt="">

            <!-- <img
              v-if="item.provider == 'IGM'"
              src="../../public/img/chart/informa_logo.png"
              alt=""
            />
            <img
              v-else-if="item.provider == '金十数据'"
              src="../../public/img/chart/informa_logo1.png"
              alt=""
            />
             <img
              v-else-if="item.provider == 'Reuters'"
              src="../../public/img/chart/lutou.png"
              alt=""
            />
             <img
              v-else-if="item.provider == 'FX168'"
              src="../../public/img/chart/caijing.png"
              alt=""
            /> -->
          </div>
        </div>
        <div class="right">
          <div class="time">{{ item.date_time }}</div>
          <div class="content">
            {{ item.title }}
          </div>
          <div class="from">{{ item.provider }}</div>
        </div>
      </li>
    </ul>
    <div class="" v-else>暂无数据</div>

    <el-drawer
      :custom-class="'detailTips'"
      :visible.sync="drawer"
      :before-close="handleClose"
    >
      <!-- <p>{{ currItem.content }}</p>
      <p>{{ currItem.date_time }}</p>
      <p>{{ currItem.provider }}</p> -->
       <div style="max-height:80%;overflow:auto;">
        <p style="">
          {{ currItem.content }}
        </p>
         <p>{{ currItem.date_time }}</p>
        <p>{{ currItem.provider }}</p>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import tools from "@/utils/tools";

export default {
  name: "detailnews",
  data() {
    return {
      loading: true,
      dynamicData: null,
      drawer: false,
      currItem: {}
    };
  },
  mixins: [tools],
  props: {
    // jldata: {
    //   type: Array
    // },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 200
    },
    title: {
      type: String,
      default: "综合行为评测"
    },
    jldata: {
      type: Array,
      default: function(e) {
        return e;
      }
    }
  },
  watch: {
    jldata(newVal) {
      this.dynamicData = newVal;
      this.currItem = this.dynamicData[0];
    }
  },
  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      // return parseInt(Math.random() * 1000000);
      return this.getID();
    }
  },
  mounted() {
    this.dynamicData = this.jldata;
    // this.currItem = this.dynamicData[0];
    this.loading = false;
  },
  methods: {
    handleClose(done) {
      done();
      this.drawer = false;
    },
    openDrawer(e) {
      this.drawer = true;
      this.currItem = e;
    }
  }
};
</script>
<style lang="scss">
.detailnews {
  overflow: auto;
  width: 100%;
  height: 400px;
  margin: 0 auto;
  // background: #191919;
  @include themeBg1();

  .newsbox {
    padding: 0 10px;
    li {
      display: flex;
      align-items: center;
      min-height: 70px;
      cursor: pointer;
      .left {
        width: 100px;
        text-align: center;

        .avr {
          border-radius: 50%;
          height: 40px;
          width: 40px;
          // background: #b4b4b4;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 40px;

            // border-radius: 50%;
          }
        }
      }
      .right {
        text-align: left;
        padding-right: 20px;
        flex: 1;
        .time {
          // color: #fdca64;
           @include news_fontColor2();

        }
        .content {
          // color: #b4b4b4;
          @include fontColor();

        }
        .from {
          // color: #b4b4b4;
          @include fontColor();
          text-align: right;
        }
      }
    }
    li:hover {
      cursor: pointer;
    }
  }
  .detailTips {
    // background: #222;
    // color: #fff;
    padding: 0 20px;
    text-indent: 40px;
    z-index: 10000;
    text-align: left;
    @include fontColor();

    @include themeBg1();

    .el-drawer__body{
      height:100%;
    }
    .el-drawer__close-btn {
      background: #a3a3a3;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      color: #fff;
    }
    .el-drawer__body {
      text-align: left;
    }
  }
}
.detailnews::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.detailnews::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(60, 50, 153, 0.2);
  background: #535353;
}
.detailnews::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 6px rgba(23, 10, 139, 0.2);
  border-radius: 10px;
  background: #ededed;
}
</style>
