<template>
  <div
    class="detail"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div>
      <!-- <el-page-header @back="goBack"> </el-page-header> -->
      <el-row class="back">
        <div @click="goBack" class="backbtn">《 返回</div>
      </el-row>
      <el-row :span="24" class="companybox">
        <e-col :span="24" class="company">
          <div class="left">
            <p class="name">{{ baseInfo.companyInfo.name }}</p>
            <p class="symbol">{{ baseInfo.companyInfo.symbol }}</p>
            <div
              class="nodata"
              v-if="
                baseInfo.companyInfo.priceChange == 0 &&
                  baseInfo.companyInfo.currentClose == 0 &&
                  baseInfo.companyInfo.currentDate == 0
              "
            >
              暂无数据
            </div>
            <div class="data">
              <p class="down" v-if="baseInfo.companyInfo.priceChange < 0">
                {{ baseInfo.companyInfo.priceChange }}%
              </p>

              <p class="up" v-else>+{{ baseInfo.companyInfo.priceChange }}%</p>
              <p class="currnum">{{ baseInfo.companyInfo.currentClose }}</p>
              <p class="from">(数据：{{ baseInfo.companyInfo.currentDate }})</p>
            </div>
          </div>
          <div class="right">
            <div class="subleft">
              <div class="ratio">
                <p class="tit">与前7天的平均值相比</p>
                <p
                  :class="{
                    green: baseInfo.companyInfo.articleCountRatio < 0,
                    red: baseInfo.companyInfo.articleCountRatio > 0
                  }"
                  class="num"
                >
                  <span v-if="baseInfo.companyInfo.articleCountRatio > 0">
                    +{{ baseInfo.companyInfo.articleCountRatio }}%
                  </span>
                  <span v-else
                    >{{ baseInfo.companyInfo.articleCountRatio }}%</span
                  >
                </p>
                <p class="line">|</p>
              </div>

              <div class="numbers">
                <p class="tit">舆情数量</p>
                <p class="num">{{ baseInfo.companyInfo.all_count }}</p>
              </div>
            </div>
            <div class="subright">
              <div class="gobefore st" @click="sendStatus(-1)">《</div>
              <div class="goafter st" @click="sendStatus(1)">》</div>
            </div>
          </div>
        </e-col>

        <el-col class="bottom"> </el-col>
      </el-row>
      <el-row :span="24" class="chart">
        <el-col :span="24">
          <div class="tit">新闻热度</div>
          <el-row type="flex" class="row">
            <el-col>
              <el-row :span="24">
                <el-col>
                  <jlring
                    :jldata="baseInfo.companyInfo"
                    :title="'文章分布'"
                    :height:="200"
                    :jltip="1"
                  ></jlring>
                </el-col>
                <el-col class="mg_left10">
                  <ringdata
                    :jldata="baseInfo.companyInfo"
                    :height:="200"
                    :title="'信心指数'"
                  ></ringdata>
                </el-col>
                <el-col class="mg_left10">
                  <jlring
                    :jldata="baseInfo.companyInfo"
                    :title="'趋势指标'"
                    :height:="200"
                    :jltip="3"
                  ></jlring>
                </el-col>
              </el-row>
              <el-row :span="24" class="row mg_top10">
                <el-col :span="12">
                  <!-- <arcprogress
                    :title="'新闻热度'"
                    :jldata="baseInfo.companyInfo"
                    :jltip="1"
                  ></arcprogress> -->
                  <gsappropress
                    :title="'新闻热度'"
                    :jldata="baseInfo.companyInfo"
                    :jltip="1"
                    :height="220"
                  ></gsappropress>
                </el-col>
                <el-col :span="12" class="mg_left10">
                  <jlrank
                    :title="'最热门讨论话题'"
                    :jldata="baseInfo.companyInfo"
                    :jltip="1"
                  ></jlrank>
                </el-col>
              </el-row>
            </el-col>
            <el-col class="mg_left10">
              <jlstackbar
                :title="'舆情历史统计'"
                :jldata="baseInfo.barData"
                :height="420"
                :jltip="
                  '“历史情绪“模块显示了历史情感数据变化趋势，与该资产价格之间的关联性。'
                "
              ></jlstackbar>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :span="24" class="chart">
        <el-col :span="24">
          <div class="tit">情绪表现</div>
          <el-row :span="24" class="row">
            <el-col :span="12">
              <el-row :span="24">
                <el-col>
                  <!-- <jlmood
                    :title="'情绪表现'"
                    :jldata="baseInfo.companyInfo"
                    :jltip="1"
                  ></jlmood> -->
                  <circleprogress
                    :title="'情绪表现'"
                    :jldata="baseInfo.companyInfo"
                    :jltip="1"
                    :width="300"
                    :height="410"
                  ></circleprogress>
                </el-col>
                <el-col class="mg_left10">
                  <newsubjective
                    :title="'客观性'"
                    :jldata="baseInfo.companyInfo"
                    :jltip="1"
                    :width="300"
                    :height="420"
                  ></newsubjective>
                </el-col>
              </el-row>
              <el-row :span="24" class="row mg_top10">
                <!-- <el-col>
                  <jlguage
                    :title="'信心指数'"
                    :jldata="baseInfo.companyInfo"
                    :jltip="
                      '信心指数“模块主要用来显示“情感评分“的可靠性。通过对新闻证据数量的算法处理，表明当前”情绪指向“信任度多少。 它分为低，中和高三个级别'
                    "
                    :width="280"
                  ></jlguage>
                </el-col> -->
                <el-col class="mg_left10">
                  <!-- <jlsubjective
                    :title="'客观性'"
                    :jldata="baseInfo.companyInfo"
                    :jltip="1"
                  ></jlsubjective> -->
                </el-col>
              </el-row>
            </el-col>
            <el-col class="mg_left10">
              <!-- <jlconfidenceband
                :title="'历史情绪'"
                :jldata="baseInfo.barData"
                :height="410"
                :jltip="
                  '“历史情绪“模块显示了历史情感数据变化趋势，与该资产价格之间的关联性。'
                "
              ></jlconfidenceband> -->
              <timeseriseline
                :title="'历史情绪'"
                :jldata="baseInfo.barData"
                :jltip="1"
              ></timeseriseline>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- <el-row :span="24" class="chart">
        <el-col :span="24">
          <div class="tit">看同行</div>
          <el-row :span="24">
            <el-col :span="8">
              <jlevalution
                :title="'综合行为评测'"
                :jldata="baseInfo.companyInfo"
                :jltip="
                  '信心指数“模块主要用来显示“情感评分“的可靠性。通过对新闻证据数量的算法处理，表明当前”情绪指向“信任度多少。 它分为低，中和高三个级别'
                "
              ></jlevalution>
            </el-col>
            <el-col :span="8">
              <jlmultarc
                :title="'分数'"
                :jldata="baseInfo.companyInfo"
                :jltip="
                  '信心指数“模块主要用来显示“情感评分“的可靠性。通过对新闻证据数量的算法处理，表明当前”情绪指向“信任度多少。 它分为低，中和高三个级别'
                "
              ></jlmultarc>
            </el-col>
            <el-col :span="8">
              <same
                :title="'同行'"
                :jldata="baseInfo.sameInfo"
                :jltip="
                  '信心指数“模块主要用来显示“情感评分“的可靠性。通过对新闻证据数量的算法处理，表明当前”情绪指向“信任度多少。 它分为低，中和高三个级别'
                "
              ></same>
            </el-col>
          </el-row>
        </el-col>
      </el-row> -->
      <el-row :span="24" class="chart">
        <el-col :span="24">
          <div class="tit">
            动态消息
            <el-dropdown :hide-on-click="false">
              <span class="el-dropdown-link dropbox">
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
                    >所有</el-checkbox
                  >
                </el-dropdown-item>
                <el-checkbox-group
                  v-model="checkedNews"
                  @change="handleCheckedNewsChange"
                >
                  <el-dropdown-item
                    v-for="(item, index) in newsArray"
                    :key="item"
                    :index="index"
                  >
                    <el-checkbox :label="item.id" :key="item">
                      {{ item.name }}</el-checkbox
                    >
                  </el-dropdown-item>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-row :span="24" class="row">
            <el-col :span="12">
              <detailnews :height="300" :jldata="baseInfo.newsArr"></detailnews>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { getInit } from "@/request/config.js";
import { mapGetters, mapActions } from "vuex";
// import selfbubble from "./../d3-components/bubble/bubble";
import jlring from "./../echart_components/ring";
import ringdata from "./../echart_components/ringdata";
// import jltrend from "@/achart/trend";

// import jlconfidenceband from "@/echart_components/confidenceband";
import timeseriseline from "@/d3-components/gsap/gsap_timeseries_line";

// import jlringprogress from "@/d3-components/ringprogress";

import jlrank from "@/achart/rank";
// import jlmood from "@/achart/mood";
import circleprogress from "./../d3-components/circleprogress.vue";
import newsubjective from "./../achart/newsubjective.vue";
import detailnews from "@/achart/detailnews";
// import jlguage from "@/echart_components/guage";
// import jlmultarc from "@/d3-components/mult_arc";
// import jlevalution from "@/achart/evaluation";
// import same from "@/achart/same";

// import jldashprogress from "@/achart/dashprogress";
import arcprogress from "./../d3-components/arc_progress";
import gsappropress from "./../d3-components/gsap/gsap_progress";
import jlstackbar from "@/echart_components/stackbar";
import tool from "./../utils/tools";
import { getNewsOrigin } from "@/request/config";

export default {
  name: "detail",
  data() {
    return {
      bubbledata: [],
      activeName: "first",
      showSubMain: false,
      subMenuChartActive: "third",
      currTabInfo: "",
      currTime: "1",
      baseInfo: {},
      companyInfo: {},
      currCompanyIdIndex: 0,
      loading: true,
      newsArray: [
        // { id: 0, name: "金十" },
        // { id: 1, name: "IGM" },
        // { id: 2, name: "路透" },
        // { id: 3, name: "财经" },
      ],
      checkAll: true,
      checkedNews: [],
      isIndeterminate: false,
      newsArr: [] //动态新闻的list
    };
  },
  mixins: [tool],
  components: {
    // selfbubble,
    jlring,
    ringdata,
    // jltrend,
    // jlconfidenceband,
    timeseriseline,
    // jlringprogress,
    jlrank,
    circleprogress,
    newsubjective,
    // jlguage,
    // jlmultarc,
    // jlevalution,
    // jldashprogress,
    // arcprogress,
    gsappropress,
    jlstackbar,
    detailnews
    // same
  },

  computed: {
    ...mapGetters("home", ["getDetailInfo", "getCompanyArr", "getCompanyId"])
  },
  watch: {
    getDetailInfo(newVal) {
      console.log("watch detail", newVal);
    }
    // 动态消息用户操作
    // checkedNews(newVal) {
    //   let checkedCount = this.checkedNews.length;
    //   checkedCount == this.newsArray.length
    //     ? (this.checkAll = true)
    //     : (this.checkAll = false);
    //   if (checkedCount > 0) {
    //     if (this.checkedNews.includes(0) && this.checkedNews.includes(1)&&this.checkedNews.includes(2)&&this.checkedNews.includes(3)) {
    //       this.newsParams = "newsfeed/v1/all";
    //       this.getNews();

    //       return;
    //     } else if (this.checkedNews.includes(0)) {
    //       this.newsParams = "newsfeed/v1/jinten";
    //       this.getNews();

    //       return;
    //     }
    //     if (this.checkedNews.includes(1)) {
    //       this.newsParams = "newsfeed/v1/informa";
    //       this.getNews();
    //       return;
    //     }
    //     if(this.checkedNews.includes(2)){
    //       this.newsParams ="/newsfeed/v1/reuters"
    //       this.getNews();
    //       return;
    //     }
    //     if(this.checkedNews.includes(3)){
    //       this.newsParams ="/newsfeed/v1/fx168"
    //       this.getNews();
    //       return;

    //     }
    //   }
    // }
  },
  created() {
    console.log("detail", this.getDetailInfo);
    this.baseInfo = this.getDetailInfo;
    this.loading = false;
  },
  mounted() {
    let _this = this;
    _this.getCompanyArr.map((item, index) => {
      if (item.entity_id == _this.getCompanyId.entity_id) {
        _this.currCompanyIdIndex = index;
      }
    });
    getNewsOrigin().then(res => {
      this.newsArray = res.data.newsFeed;
      this.newsArray.map(item => {
        this.checkedNews.push(item.id);
      });
    });
  },
  methods: {
    ...mapActions("home", [
      "setDetail",
      "setDetailId",
      "setCompany",
      "setSelectNews"
    ]),
    goBack() {
      this.$router.go(-1);
    },
    handleCheckAllChange(val) {
      if (val) {
        let temp = [];
        this.newsArray.map(item => {
          temp.push(item.id);
        });
        this.checkedNews = temp;
        //发起请求
        this.newsParams = "newsfeed/v1/all";
        this.getNews();
      } else {
        this.newsArr = [];
        this.checkedNews = [];
      }
    },
    handleCheckedNewsChange(value) {
      // console.log(this.checkedNews);
      //  console.log(this.checkedNews);
      let _this = this;
      let checkedCount = this.checkedNews.length;
      this.newsArr = [];
      checkedCount == this.newsArray.length
        ? (this.checkAll = true)
        : (this.checkAll = false);

      if (checkedCount > 0) {
        if (this.checkAll) {
          this.newsParams = "newsfeed/v1/all";
          this.getNews();
          return;
        } else {
          _this.checkedNews.map(i => {
            _this.newsArray.map(j => {
              if (i == j.id) {
                _this.newsParams = `newsfeed/v1/${j.urlName}`;
                _this.getNews();
              }
            });
          });
        }
      }
    },
    sendStatus(e) {
      this.currCompanyIdIndex += e;
      // console.log(this.getCompanyArr[this.currCompanyIdIndex]);
      this.getCompanyData(this.getCompanyArr[this.currCompanyIdIndex]);
    },

    initData() {
      // let param = `${this.currTime}/${base[this.getCurrPage["icon"]]}`;
      // getInit(param).then(res => {
      //   console.log(res.data.articleCounts);
      //   this.bubbledata = res.data.articleCounts;
      // });
    },
    changeTime(data) {
      // console.log("change", data);
      this.currTime = data;
      this.initData();
    },
    selectCompany(data) {
      // console.log(data);
      this.changeSubMain(true);
    },
    changeSubMain(a) {
      if (a) {
        this.showSubMain = true;
      } else {
        this.showSubMain = !this.showSubMain;
      }
    }
  }
};
</script>
<style lang="scss">
.detail {
  // color: #fff;
  padding: 0px 20px 40px 20px;
  // background: #222222;
  @include fontColor();
  @include themeBg();
  .back {
    color: #0ba3f0;
    font-size: 22px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    .backbtn {
      display: inline-block;
      cursor: pointer;
    }
    .backbtn:hover {
      cursor: pointer;
    }
  }
  .el-col {
    text-align: center;
  }
  .companybox {
    p {
      margin-left: 20px;
    }
    .company {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        height: 40px;
        border-bottom: 4px;
        border-color: #0ba3f1;
        .nodata {
          display: flex;
          align-items: center;
        }
        .data {
          display: flex;

          align-items: center;
        }
        .name {
          margin-left: 20px;
          font-size: 22px;
        }
        .symbol {
          font-size: 12px;
          color: #a7a7a7;
          margin-left: 20px;
        }
        .down {
          color: green;
        }
        .up {
          color: #ff4c4c;
        }
        .from {
          font-size: 16px;
          color: #a7a7a7;
        }
      }
      .right {
        display: flex;
        align-items: center;

        .subleft {
          display: flex;
          align-items: center;
          .ratio {
            display: flex;
            align-items: center;

            .tit {
              font-size: 22px;
            }
            .num {
              color: #4cd2ff;
              font-size: 22px;
              font-weight: bold;
            }
            .line {
              color: #0ba3f0;
            }
          }
          .numbers {
            display: flex;
            align-items: center;
            .tit {
              font-size: 22px;
            }
            .num {
              color: #ffdc72;
              font-size: 22px;
              font-weight: bold;
            }
          }
        }
        .subright {
          display: flex;
          align-items: center;

          .st {
            background: #0ba3f1;
            color: #fff;
            padding: 5px 10px;
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }
    .bottom {
      height: 4px;
      background: #0ba3f1;
    }
  }
  .chart {
    .row {
      display: flex;
      .el-col {
        flex: 1;
        .el-row {
          display: flex;
          .el-col {
            flex: 1;
          }
        }
      }
    }
    .mg_top10 {
      margin-top: 10px;
    }
    .mg_left10 {
      margin-left: 10px;
    }
    .dropbox {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      background: grey;
      text-align: center;
      color: #fff;
      .el-icon--right {
        margin: 0 !important;
      }
    }
    .el-col {
      .tit {
        text-align: left;
        height: 30px;
        line-height: 30px;
        width: 120px;
        margin-top: 10px;
        background: #0ba3f1;
        // color: black;
        text-align: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        @include fontColor4();
      }
    }
  }
}
</style>
